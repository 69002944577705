import {
  updateOfferPayout,
  updateOfferSource,
  updateOfferAsyncInfo,
  getOfferDetail,
  handleCopyMix,
} from 'api/offer/detail';
import {
  api,
  getOfferList,
  updateOfferStatus,
  batchApprovalAffs,
  batchHold,
  batchActive,
  getPidsList,
  getPbDomainList,
  handleUpdataOfferLinkType,
  handleReplacePb,
  batchOfferSourceLink,
  batchOfferImpressionLink,
  handleBatchOfferEventPayoutInfo,
  handleOfferPayoutType,
} from 'api/product/rankSearch';
import { getSubruleLogList, getSubruleCountList, getReportSubruleList } from 'api/product/subrule';
import { confirmTraffic } from '@/api/affiliate/detail';
import Upload from '@/components/FileUpload';
import Pagination from '@/components/pagination';
import GroupSelect from '@/components/GroupSelect';
import AutoMix from '../../offer/components/autoMix';

import qs from 'querystring';
import { myMixin } from '@/mixins/mixins.js';
import optionData from '@/assets/js/optionData';
import { mapState, mapActions } from 'vuex';
import { cloneDeep } from 'lodash-es';
import { getPermissionAllList } from 'api/account/permission';
import { offerTestLink } from 'api/callback';
import { ValiNumberAndFloat } from 'utils/validate';
import { updateOfferManagerRule } from '@/api/offer/detail';

var clickTimer = null;

export default {
  mixins: [myMixin],
  components: {
    Pagination,
    Upload,
    GroupSelect,
    AutoMix,
  },
  data() {
    return {
      parentMessage: '1',
      countries: '',
      carriersGroup: [],
      listLoading: false,
      payoutFlag: false,
      percentFlag: false,
      toAffVisible: false,
      saveBtnLoading: false,
      tipsData: [],
      doUpload: '/trans/offer/downloadTopOffers',
      fileTemplateUrl: api.FILE_TEMPLATE,
      dataPram: null,
      total: null,
      updateVisible: false,
      offersVisible: false,
      batchImportErrorVisible: false,
      batchImportError: [],
      copyArr: [],
      updateOffer: {
        offerId: null,
        payout: null,
        revenue: null,
      },
      offers: '',
      offerList: [],
      fullWidth: document.documentElement.clientWidth,
      pids: [],
      pbDomains: [],
      replacePbVisible: false,
      replacePb: {
        campaign: '',
        event_callback: '',
      },
      rankingStatusOptions: [
        {
          label: 'ALL',
          value: null,
        },
        {
          label: '跳转失败',
          value: 0,
        },
        {
          label: '跳转成功',
          value: 1,
        },
        {
          label: '请求失败',
          value: -1,
        },
      ],
      carriers: optionData.carriers,
      rankingOptions: [
        {
          label: 'Default',
          value: '',
        },
        {
          label: 'RedirectSuccess',
          value: 'RedirectSuccess',
        },
        {
          label: 'RedirectTime',
          value: 'RedirectTime',
        },
        {
          label: 'Payout',
          value: 'Payout',
        },
        {
          label: 'convs',
          value: 'convs',
        },
      ],
      statusOptions: [
        {
          label: 'ACTIVE',
          value: 'ACTIVE',
        },
        {
          label: 'PENDING',
          value: 'PENDING',
        },
        {
          label: 'PAUSED',
          value: 'PAUSED',
        },
        {
          label: 'HOLD',
          value: 'HOLD',
        },
        {
          label: 'BLOCK',
          value: 'BLOCK',
        },
        {
          label: 'STOP',
          value: 'STOP',
        },
        {
          label: 'SUPPLEMENT',
          value: 'SUPPLEMENT',
        },
        {
          label: 'ALL',
          value: 'ALL',
        },
      ],
      marketOptions: [
        {
          label: 'ALL',
          value: null,
        },
        {
          label: 'Google Play',
          value: 'GP',
        },
        {
          label: 'App Store',
          value: 'ITUNES',
        },
      ],
      managerOption: [],
      filter: {
        sourceIds: [],
        pids: [],
        offerTypes: [],
        pbDomains: [],
        offerIds: '',
        sourceOfferIds: '',
        status: 'ACTIVE',
        sourceName: '',
        offerName: '',
        prodName: '',
        market: '',
        lowPayout: '',
        highPayout: '',
        countries: '',
        ranking: '',
        redirectStatus: null,
        maxRedirectCount: '',
      },
      pageParam: {
        page: 1,
        pageSize: 20,
      },
      checkedBox: {},
      multipleSelection: [],
      loading: false,
      payoutSettingVisible: false,
      offerPayout: {},
      subLog: {
        visible: false,
        list: [],
        countVisible: false,
        countList: [],
      },
      payout: {
        visible: false,
        list: {},
        loading: false,
      },
      batchEventPayout: {
        visible: false,
        list: {
          eventCapDaily: [
            {
              revenue: '',
              payout: '',
              eventName: '',
              currency: 'USD',
              capDaily: '',
            },
          ],
        },
        loading: false,
      },
      options: {
        rule: [],
      },
      upManagerAndSubRule: {
        offerId: '',
        ruleId: '',
      },
      upManagerAndSubRuleVisible: false,
      offerLinkType: {
        visible: false,
        data: {},
        loading: false,
      },
      offerPayoutType: {
        visible: false,
        data: {
          type: '',
        },
        loading: false,
      },
      offerSource: {
        visible: false,
        batchVisible: false,
        offerId: '',
        offerIds: [],
        sourceTrackingLink: '',
      },
      autoMix: {
        visible: false,
        batchMixErrorVisible: false,
        batchMixError: [],
      },
      rules: {
        // dailyCap: [
        //   {
        //     required: true,
        //     message: 'add cap here',
        //     trigger: 'blur',
        //   },
        //   { type: 'number', message: 'Must be a numeric value' },
        // ],
        payout: [
          {
            required: false,
            message: 'add payout here',
            trigger: 'blur',
          },
        ],
        event_callback: [
          {
            required: true,
            message: 'Please enter Event Callback',
            trigger: 'blur',
          },
        ],
      },
      payoutInfoRules: {
        revenue: [
          {
            required: true,
            message: 'please add revenue',
            trigger: 'blur',
          },
        ],
        payout: [
          {
            required: true,
            message: 'please add payout',
            trigger: 'blur',
          },
        ],
      },
      deductOption: [
        {
          label: 'Disabling',
          value: 0,
        },
        {
          label: 'Available',
          value: 1,
        },
      ],
      batchSourceUrl: '',
      impressionInfo: {
        impressionTracking: '',
        impressionOpen: 0,
        impressionRatio: 0,
        offerIds: [],
      },
      impressionVisible: false,
      impressionRules2: {},
      impressionRules: {
        impressionTracking: [
          { required: true, message: 'please add impression tracking', trigger: 'blur' },
        ],
        impressionRatio: [
          { required: true, message: 'please add impression Ratio', trigger: 'blur' },
          { validator: ValiNumberAndFloat, trigger: ['blur', 'change'] },
        ],
      },
      dialog: {
        visible: false,
        title: '--',
        type: '',
      },
      offerDetailVisible: false,
      copyMixData: {},
    };
  },
  computed: {
    ...mapState('affiliate', {
      affiliatesGroupList: (state) => state.affiliatesGroupList,
      affiliateLoading: (state) => state.affiliateLoading,
    }),
    ...mapState('source', {
      sourcesList: (state) => state.sourcesList,
      sourceLoading: (state) => state.sourceLoading,
    }),
  },
  mounted() {
    this.payoutFlag = false;
    this.percentFlag = false;
    this.getAffiliateList();
    this.getSourceIdsList();
    this.managerList();
    this.pidsList();
    this.pbDomainList();
    this.subruleList();
    for (var key in this.carriers) {
      this.carriersGroup.push({
        label: key,
        children: this.carriers[key],
      });
    }
    console.log(document.body.clientWidth);
  },
  methods: {
    ...mapActions('affiliate', ['getAffiliateList']),
    ...mapActions('source', ['getSourceIdsList']),
    onCopy() {
      if (this.copyArr == [] || this.copyArr.length == 0) {
        this.onError();
        return;
      }
      this.$message({
        message: 'Copy success',
        type: 'success',
      });
    },
    onError() {
      this.$message.error('Copy Error');
    },
    subRuleDetailClick(row) {
      console.log('dzk');
      if (clickTimer) {
        window.clearTimeout(clickTimer);
        clickTimer = null;
      }
      var that = this;
      clickTimer = window.setTimeout(function() {
        that.$nextTick(() => {
          const { href } = that.$router.resolve({
            path: '/offer/subrule/edit',
            query: {
              id: row.ruleId,
            },
          });
          window.open(href, '_blank');
        });
      }, 300);
    },
    filterDomins(domains) {
      var content = '';
      if (domains !== '' && domains !== null && domains !== undefined) {
        domains.split(',').forEach((item, i) => {
          content += i + ' : ' + item + '</br>';
        });
      }
      return content;
    },
    pidsList() {
      getPidsList().then((res) => {
        this.pids = res.result;
      });
    },
    // 获取manager列表
    managerList() {
      getPermissionAllList().then((res) => {
        console.log(res);
        this.managerOption = res.result;
      });
    },
    //获取pb-domain列表
    pbDomainList() {
      getPbDomainList().then((res) => {
        this.pbDomains = res.result;
      });
    },
    getOfferList(curPage) {
      if (curPage) {
        this.pageParam.page = curPage;
      }
      this.dataPram = this.copy(this.filter);
      if (Array.isArray(this.dataPram.sourceIds) && this.dataPram.sourceIds.length > 0) {
        this.dataPram.sourceIds = this.dataPram.sourceIds.join();
      } else {
        delete this.dataPram.sourceIds;
      }
      for (const key in this.dataPram) {
        if (this.dataPram[key] === '') {
          delete this.dataPram[key];
        }
      }
      this.dataPram.pids = this.dataPram.pids.toString();
      this.dataPram.offerTypes = this.dataPram.offerTypes.toString();
      this.dataPram.pbDomains = this.dataPram.pbDomains.toString();
      this.offerListApi();
    },
    offerListApi() {
      this.listLoading = true;
      let param = {
        ...this.dataPram,
        page: this.pageParam.page,
        pageSize: this.pageParam.pageSize,
      };
      getOfferList(param).then((response) => {
        this.listLoading = false;
        if (response.code == 200) {
          this.offerList = response.result;
          this.offers = this.offerList?.map((item) => item.offerId).join();
          this.total = response.total;
        } else {
          this.$message.error(response.message);
          this.offerList = [];
          this.total = null;
          this.offers = null;
        }
      });
    },
    handleSizeChange(size) {
      this.pageParam.pageSize = size;
      this.handleCurrentChange(1);
    },
    async handleCurrentChange(currentPage) {
      this.pageParam.page = currentPage;
      this.getOfferList();
    },
    handleUploadSuccess() {},
    change_Status(id) {
      updateOfferStatus(id).then((response) => {
        if (response.code == 200) {
          this.$message({
            message: response.message,
            type: 'success',
          });
          this.getOfferList();
        } else {
          this.$message.error(response.message);
        }
      });
    },
    update_offer(row) {
      this.offerPayout = cloneDeep(row);
      this.payoutSettingVisible = true;
    },
    copy_offer(column, row) {
      let copyOffer = {};
      if (column.label == 'Option') {
        copyOffer = this.copy(row);
        const { href } = this.$router.resolve({
          path: '/offer/add',
        });
        sessionStorage.setItem('copyOffer', JSON.stringify(copyOffer));
        window.open(href, '_blank');
      }
    },
    offerPayoutChange() {
      const param = {
        payout: this.offerPayout.payout,
        revenue: this.offerPayout.revenue,
        offerId: this.offerPayout.offerId,
      };
      updateOfferPayout(param).then((res) => {
        if (res.code === 200) {
          this.$message.success('修改成功');
          this.payoutSettingVisible = false;
          this.getOfferList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    detailClick(row) {
      if (clickTimer) {
        window.clearTimeout(clickTimer);
        clickTimer = null;
      }
      var that = this;
      clickTimer = window.setTimeout(function() {
        that.$nextTick(() => {
          const { href } = that.$router.resolve({
            path: '/offer/detail',
            query: {
              offerId: row.offerId,
              country: row.countries,
              os: row.platforms,
            },
          });
          window.open(href, '_blank');
        });
      }, 300);
    },
    sourcedetailClick(row) {
      const { href } = this.$router.resolve({
        path: '/source/detail',
        query: {
          sourceId: row.sourceId,
        },
      });
      window.open(href, '_blank');
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;

      this.copyArr = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        this.copyArr.push(this.multipleSelection[i].offerId);
      }
    },
    downloadClick() {
      let param = {};
      for (const key in this.filter) {
        if (!this.filter[key]) {
          continue;
        }
        if (this.filter[key] && Array.isArray(this.filter[key])) {
          if (this.filter[key].length === 0) {
            continue;
          }
          param[key] = this.filter[key].toString();
        } else if (this.filter[key]) {
          param[key] = this.filter[key];
        }
      }
      let strParam = qs.stringify(param);
      window.open(`/api/offer/downloadTopOffers?${strParam}`, '_blank');
    },
    batchClick(type) {
      if (type !== 3 && this.multipleSelection.length == 0) {
        this.$message.error('do not choose offer!');
        return;
      }
      var offerIds = new Array(this.multipleSelection.length);
      for (var i = 0; i < this.multipleSelection.length; i++) {
        offerIds[i] = this.multipleSelection[i].offerId;
      }
      if (type == 1) {
        this.$confirm('Are you want to Hold these Offers？', 'Message', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning',
        })
          .then(() => {
            let param = {
              offerIds: offerIds,
            };
            batchHold(param).then((response) => {
              if (response.code === 200) {
                this.$message({
                  message: 'Success',
                  type: 'success',
                });
                this.getOfferList();
              } else if (response.code === 500) {
                this.$message({
                  showClose: true,
                  duration: 0,
                  message: response.message,
                  type: 'warning',
                });
              } else {
                this.$message.error('failed,params is error!! ');
              }
            });
          })
          .catch(() => {
            this.$message({
              message: 'You have cancel',
              type: 'info',
            });
          });
      }
      if (type == 2) {
        this.$confirm('Are you want to Active these Offers？', 'Message', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning',
        })
          .then(() => {
            let param = {
              offerIds: offerIds,
            };
            batchActive(param).then((response) => {
              if (response.code === 200) {
                this.$message({
                  message: response.message,
                  type: 'success',
                });
                this.getOfferList();
              } else if (response.code === 500) {
                this.$message({
                  showClose: true,
                  duration: 0,
                  message: response.message,
                  type: 'warning',
                });
              } else {
                this.$message.error(response.message);
              }
            });
          })
          .catch(() => {
            this.$message({
              message: 'You have cancel',
              type: 'info',
            });
          });
      }
      if (type === 3) {
        // window.open(`${api.OFFER_BATCH_IMPORT}`, '_blank');
        window.open(
          'https://bid-offer-creatives.oss-us-east-1.aliyuncs.com/%E8%87%AA%E5%8A%A8%E4%B8%8A%E5%8D%95%E6%A8%A1%E6%9D%BF.xlsx',
          '_blank'
        );
        this.$message.success('数据导出成功！');
      }
    },
    batchOfferTest() {
      var offerIds = new Array(this.multipleSelection.length);
      for (var i = 0; i < this.multipleSelection.length; i++) {
        offerIds[i] = this.multipleSelection[i].offerId;
      }
      let parma = JSON.stringify(offerIds);
      console.log(parma);
      offerTestLink(parma).then((response) => {
        if (response.success === true) {
          this.$message({
            message: 'Update Success',
            type: 'success',
          });
          this.getOfferList();
        } else {
          this.$message.error('Update Error:' + response.message);
        }
      });
    },
    batchOfferSourceLinkv2() {
      this.$prompt('请输入Source track Link', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputType: 'textarea',
        inputValue: this.batchSourceUrl,
        closeOnClickModal: false,
        inputValidator: (v) => {
          if (v != '') {
            var reg = /^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/;
            if (!reg.test(v)) {
              return false;
            }
          } else {
            return '不能为空！';
          }
        },
        inputErrorMessage: '',
      })
        .then(({ value }) => {
          var offerIds = new Array(this.multipleSelection.length);
          for (var i = 0; i < this.multipleSelection.length; i++) {
            offerIds[i] = this.multipleSelection[i].offerId;
          }
          console.log(offerIds + '->>' + value);
          batchOfferSourceLink({
            offerIds: offerIds,
            sourceTrackingLink: value,
          }).then((res) => {
            if (res.code === 200) {
              this.getOfferList();
              this.$message.success(res.message);
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入',
          });
        });
    },
    batchUpdateOfferSourceLink(type) {
      if (type) {
        this.offerSource.batchVisible = true;
        this.offerSource.sourceTrackingLink = '';
      } else {
        var offerIds = new Array(this.multipleSelection.length);
        for (var i = 0; i < this.multipleSelection.length; i++) {
          offerIds[i] = this.multipleSelection[i].offerId;
        }
        batchOfferSourceLink({
          offerIds: offerIds,
          sourceTrackingLink: this.offerSource.sourceTrackingLink,
        })
          .then((res) => {
            if (res.code === 200) {
              this.offerSource.batchVisible = false;
              this.getOfferList();
              this.$message.success(res.message);
            } else {
              this.$message.error(res.message);
            }
          })
          .catch((e) => {
            this.$message.error(e);
          });
      }
    },
    updateImpressionClick() {
      this.$refs.impressionForm.validate((valid) => {
        if (!valid) return;
        const impressionParams = { ...this.impressionInfo };
        impressionParams.impressionRatio = this.impressionInfo.impressionRatio * 1;
        var offerIds = new Array(this.multipleSelection.length);
        for (var i = 0; i < this.multipleSelection.length; i++) {
          offerIds[i] = this.multipleSelection[i].offerId;
        }
        impressionParams.offerIds = offerIds;
        batchOfferImpressionLink(impressionParams)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success('Change Success');
              this.impressionVisible = false;
              this.offerListApi();
              this.$refs.impressionForm.resetFields();
            } else {
              this.$message.error(res.message);
            }
          })
          .catch((error) => {
            this.$message.error(error);
          });
      });
    },
    updateTmpressionClick() {
      this.impressionVisible = true;
    },

    updateBatchEventClick() {
      this.batchEventPayout.visible = true;
    },

    confirmaddToAff(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.checkedBox.dailyCap < 0) {
            this.$message.error('Cap cannot be less than 0!');
            return;
          }
          const offerIds = new Array(this.multipleSelection.length);
          for (let i = 0; i < this.multipleSelection.length; i++) {
            offerIds[i] = this.multipleSelection[i].offerId;
          }

          offerIds.forEach((item) => {
            this.offerList.find((d) => {
              if (d.offerId == item) {
                if (this.checkedBox.payout > d.payout * 2) {
                  this.$message.error('payout setting is so high');
                  return;
                }
              }
            });
          });
          const param = {
            ...this.checkedBox,
          };

          param['offerIds'] = offerIds;
          const confirmText = [
            'You choose offer white list:',
            offerIds.toString(),
            'For affiliate:',
            param.affiliateIds + ',',
          ];
          const newDatas = [];
          const h = this.$createElement;

          for (const i in confirmText) {
            newDatas.push(
              h(
                'p',
                {
                  style: 'word-wrap: break-word',
                },
                confirmText[i]
              )
            );
          }
          confirmTraffic(offerIds).then((resp) => {
            if (resp.code === 200) {
              if (resp.result && resp.result.tips) {
                newDatas.push(
                  h(
                    'p',
                    {
                      style: 'color:red;font-weight:bolder',
                    },
                    resp.result.tips
                  )
                );
              }
              if (resp.result && resp.result.source) {
                newDatas.push(
                  h(
                    'p',
                    {
                      style: 'color:red;font-weight:bolder',
                    },
                    `SourceIds:${resp.result.source.join(',')}`
                  )
                );
              }

              this.$confirm('Tip', {
                message: h('div', null, newDatas),
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
              })
                .then(() => {
                  batchApprovalAffs(param).then((response) => {
                    if (response.code == 200) {
                      this.$message({
                        message: 'Success',
                        type: 'success',
                      });

                      this.tipsData = response.result || [];
                      if (this.tipsData.length > 0) {
                        for (var i = 0; i < this.tipsData.length; i++) {
                          let offerids = this.tipsData[i].offerIds.toString();
                          this.tipsData[i].offerIds = offerids;
                        }
                      }
                    } else {
                      this.$message.error(response.message);
                      this.tipsData = new Array({
                        affiliateId: param.affiliateIds,
                        msg: response.message,
                        offerIds: offerIds,
                      });
                    }
                  });
                  this.toAffVisible = true;
                })
                .catch(() => {
                  this.$message({
                    message: 'You have cancel',
                    type: 'info',
                  });
                });
            }
          });
        }
      });
    },
    limitPayoutOrPercent(flag) {
      if (flag == 1 && this.checkedBox.payout != null && this.checkedBox.payout != '') {
        this.payoutFlag = false;
        this.percentFlag = true;
      } else if (
        flag == 2 &&
        this.checkedBox.payoutPercent != null &&
        this.checkedBox.payoutPercent != ''
      ) {
        this.payoutFlag = true;
        this.percentFlag = false;
      } else {
        this.payoutFlag = false;
        this.percentFlag = false;
      }
    },
    setNullInput() {
      this.filter.sourceIds = [];
      this.filter.offerIds = '';
      this.filter.sourceOfferIds = '';
      this.filter.status = 'ACTIVE';
      this.filter.sourceName = '';
      this.filter.offerName = '';
      this.filter.prodName = '';
      this.filter.market = '';
      this.filter.lowPayout = '';
      this.filter.highPayout = '';
      this.filter.countries = '';
      this.filter.ranking = '';
      this.filter.redirectStatus = '';
      this.filter.maxRedirectCount = '';
    },
    /**
     * 复制信息到剪贴板
     */
    copyOfferInfo(offer) {
      const copyText = `
          offerid:${offer.offerId || ''}
          offername:${offer.offerName || ''}
          preview link:${offer.previewLink || ''}
          description:${offer.description || ''}
          payout:${offer.payout || ''}
          countries:${offer.payoutType || ''}
          tracking link:${offer.trackingLink || ''}`;
      this.$copyText(copyText)
        .then((e) => {
          this.$message.success('Copy Succee');
          console.log(e);
        })
        .catch((e) => {
          this.$message.error('Copy the failure');
          console.log(e);
        });
    },
    subLogFun(row) {
      getSubruleLogList({
        offerId: row.offerId,
      }).then((res) => {
        this.subLog.list = [];
        this.subLog.list = res.result;
        this.subLog.visible = true;
      });
    },
    subCountFun(row) {
      getSubruleCountList({
        offerId: row.offerId,
      }).then((res) => {
        this.subLog.countList = [];
        this.subLog.countList = res.result;
        this.subLog.countVisible = true;
      });
    },
    // 修改
    // sourcetracklinlk
    updateSourcetracklink(offerId, url) {
      this.$prompt('请输入Source track Link', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputType: 'textarea',
        inputValue: url,
        closeOnClickModal: false,
        inputValidator: (v) => {
          if (v != '') {
            var reg = /^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/;
            if (!reg.test(v)) {
              return false;
            }
          } else {
            return '不能为空！';
          }
        },
        inputErrorMessage: '',
      })
        .then(({ value }) => {
          updateOfferSource({
            offerId: offerId,
            sourceTrackingLink: value,
          }).then((res) => {
            if (res.code === 200) {
              this.getOfferList();
              this.$message.success(res.message);
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入',
          });
        });
    },
    updateOfferSource(offerId, url) {
      if (offerId) {
        this.offerSource.offerId = offerId;
        this.offerSource.sourceTrackingLink = url;
        this.offerSource.visible = true;
      } else {
        updateOfferSource({
          offerId: this.offerSource.offerId,
          sourceTrackingLink: this.offerSource.sourceTrackingLink,
        })
          .then((res) => {
            if (res.code === 200) {
              this.offerSource.visible = false;
              this.getOfferList();
              this.$message.success(res.message);
            } else {
              this.$message.error(res.message);
            }
          })
          .catch((e) => {
            this.$message.error(e);
          });
      }
    },
    // 更新ProdName
    updateProdName(offerId, platforms, prodName) {
      this.$prompt('请输入Prod Name', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: prodName,
        closeOnClickModal: false,
        inputValidator: (v) => {
          if (v == '') {
            return '不能为空！';
          }
        },
        inputErrorMessage: '',
      })
        .then(({ value }) => {
          updateOfferAsyncInfo({
            offerId: offerId,
            platforms: platforms,
            prod: value,
          }).then((res) => {
            if (res.code === 200) {
              this.getOfferList();
              this.$message.success(res.message);
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入',
          });
        });
    },
    // 更新offer name
    updateOfferName(offerId, offerName) {
      this.$prompt('请输入offer name', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: offerName,
        closeOnClickModal: false,
        inputValidator: (v) => {
          if (v == '') {
            return '不能为空！';
          }
        },
        inputErrorMessage: '',
      })
        .then(({ value }) => {
          updateOfferAsyncInfo({
            offerId: offerId,
            offerName: value,
          }).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message);
              this.getOfferList();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入',
          });
        });
    },
    // 更新 payout
    updatePayout(offerId) {
      this.payout.visible = true;
      this.payout.loading = true;
      getOfferDetail(offerId).then((res) => {
        console.log(res);
        this.payout.loading = false;
        this.payout.list = res.result;
        this.payout.list.eventCapDaily = JSON.parse(res.result.eventCapDaily);
      });
    },

    // 添加update payout item
    addPayoutInfoItem() {
      this.payout.list.eventCapDaily.push({
        revenue: '',
        payout: '',
        eventName: '',
        currency: 'USD',
        capDaily: '',
      });
    },
    // 添加update payout item
    addEventPayoutInfoItem() {
      this.batchEventPayout.list.eventCapDaily.push({
        revenue: '',
        payout: '',
        eventName: '',
        currency: 'USD',
        capDaily: '',
      });
    },

    UpNumber(e) {
      // 输入框中只允许输入数字
      // e.target.value = e.target.value.replace(/[^\d]/g, '');
      // 输入框中只允许输入小数点和数字，小数点后只能跟一位
      e.target.value = e.target.value.match(/^\d*(\.?\d{0,4})/g)[0] || null;
    },
    // del payout info item
    delPayoutInfoItem(index) {
      this.payout.list.eventCapDaily.splice(index, 1);
    },
    delEventPayoutInfoItem(index) {
      this.batchEventPayout.list.eventCapDaily.splice(index, 1);
    },
    // 更新 payout
    updatePayoutSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return;
        const param = Object.assign({}, this.payout.list);
        param.eventCapDaily = JSON.stringify(this.payout.list.eventCapDaily);
        this.payout.loading = true;
        updateOfferPayout(param).then((res) => {
          if (res.code === 200) {
            this.$message.success('修改成功');
            this.payout.visible = false;
            this.payout.loading = false;
            this.getOfferList();
          } else {
            this.payout.loading = false;
            this.$message.error(res.message);
          }
        });
      });
    },
    updateEventPayoutSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return;
        this.batchEventPayout.loading = true;
        const query = {
          eventCapDaily: JSON.stringify(this.batchEventPayout.list.eventCapDaily),
          offerIds: this.copyArr,
        };
        handleBatchOfferEventPayoutInfo(query).then((res) => {
          if (res.code === 200) {
            this.batchEventPayout.loading = false;
            this.$message.success('提交成功！');
            this.batchEventPayout.visible = false;
            this.getOfferList();
          } else {
            this.batchEventPayout.loading = false;
            this.$message.error(res.message);
          }
        });
      });
    },
    updateOfferLinkType(offerId, data, type) {
      console.log(type);
      if (offerId && data) {
        if (type === 'pid') {
          this.offerLinkType.data = { pid: data, offerId: offerId, type };
        }
        if (type === 'offerLinkType') {
          this.offerLinkType.data = { linkType: data, offerId: offerId, type };
        }
        this.offerLinkType.visible = true;
      } else {
        let query = Object.assign({}, this.offerLinkType.data);
        delete query.type;
        handleUpdataOfferLinkType(query).then((res) => {
          if (res.code == 200) {
            console.log(res);
            this.$message.success('更新成功！');
            this.offerLinkType.visible = false;
            this.getOfferList();
          } else {
            this.$message.error('Update Error:' + res.message);
          }
        });
      }
    },
    clickPayoutType(offerId, offerPayoutType) {
      this.offerPayoutType.visible = true;
      this.offerPayoutType.data.id = offerId;
      this.offerPayoutType.data.type = offerPayoutType;
    },
    updateOfferPayoutType() {
      handleOfferPayoutType({
        id: this.offerPayoutType.data.id,
        offerPayoutType: this.offerPayoutType.data.type,
      }).then((res) => {
        if (res.code == 200) {
          console.log(res);
          this.$message.success('更新成功！');
          this.offerPayoutType.visible = false;
          this.getOfferList();
        } else {
          this.$message.error('Update Error:' + res.message);
        }
      });
    },
    // 更新PB
    submitReplacePb(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.multipleSelection.length > 0) {
            let offerIds = [];
            for (let i = 0; i < this.multipleSelection.length; i++) {
              offerIds.push(this.multipleSelection[i].offerId);
            }
            handleReplacePb([
              {
                offerids: offerIds.toString(),
                campaign: this.replacePb.campaign,
                event_callback: this.replacePb.event_callback,
              },
            ]).then((res) => {
              this.replacePbVisible = false;
              if (res.code == 200) {
                this.$message({
                  message: '更新成功！',
                  type: 'success',
                });
              }
              if (res.code == 500) {
                this.$message({
                  message: res.message,
                  type: 'warning',
                  duration: 0,
                  showClose: true,
                });
              }
              this.replacePb.campaign = '';
              this.replacePb.event_callback = '';
            });
          } else {
            this.$message.error('Please select offer');
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleBatchImportUploadSuccess(res) {
      if (res.result.length > 0) {
        this.batchImportError = res.result;
        this.batchImportErrorVisible = true;
      }
    },
    BatchMix() {
      this.countries = this.multipleSelection.map((item) => item.countries).join(',');
      console.log('ranking country:' + this.countries);
      this.autoMix.visible = true;
      this.$nextTick(() => {
        this.$refs.getAutoMix.init();
      });
    },
    openCopyDialog(type, row) {
      this.dialog.type = type;
      this.dialog.title = type === 'add' ? '添加' : '编辑';
      this.dialog.visible = true;
      this.postData = {};
      if (row) {
        this.postData = Object.assign({}, row);
      }
    },
    // 关闭弹窗
    closeCopyDialog(formName) {
      this.$refs[formName].resetFields();
      this.dialog.visible = false;
    },
    closeMixDialog(ruleId) {
      this.autoMix.visible = false;
      if (ruleId != null && ruleId != '') {
        this.getOfferList();
      }
    },
    openErrorDialog(val) {
      this.autoMix.batchMixErrorVisible = true;
      this.autoMix.batchMixError = val;
    },
    updateManagerAndSubRule(offerId, ruleId) {
      this.upManagerAndSubRule.offerId = offerId;
      this.upManagerAndSubRule.ruleId = ruleId;
      this.upManagerAndSubRuleVisible = true;
    },
    // 保存manager-subrue
    upManagerAndSubRuleClick() {
      this.saveBtnLoading = true;
      const query = {
        offerId: this.upManagerAndSubRule.offerId,
        ruleId: this.upManagerAndSubRule.ruleId,
      };
      updateOfferManagerRule(query)
        .then((res) => {
          if (res.code !== 200 && res.success == false) {
            this.$message.error(res.message);
            this.saveBtnLoading = false;
          } else {
            this.getOfferList();
            this.$message({
              message: '更新成功',
              type: 'success',
            });
            this.saveBtnLoading = false;
          }
          this.upManagerAndSubRuleVisible = false;
        })
        .catch((error) => {
          this.$message.error('更新失败！' + error.toString());
          this.saveBtnLoading = false;
          this.upManagerAndSubRuleVisible = false;
        });
    },
    detailAutoMixClick() {
      this.offerDetailVisible = true;
    },
    copyAutoMIx() {
      var offerIds = new Array(this.multipleSelection.length);
      for (var i = 0; i < this.multipleSelection.length; i++) {
        offerIds[i] = this.multipleSelection[i].offerId;
      }
      this.copyMixData.offerIds = offerIds;
      handleCopyMix(this.copyMixData)
        .then((res) => {
          if (res.success === true) {
            this.openErrorDialog(res.result);
            this.getOfferList();
            this.dialog.visible = false;
          } else {
            console.log('66666');
            this.$message.error(res.message);
            this.dialog.visible = false;
          }
        })
        .catch((error) => {
          this.$message.error('复制失败！' + error.toString());
          this.dialog.visible = false;
        });
    },
    // 获取subrule列表
    subruleList() {
      getReportSubruleList({ status: 1 }).then((res) => {
        this.options.rule = res.result;
      });
    },
  },
};
